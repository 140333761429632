@import "animate.css/animate.min.css";

* {
    font-family: urbane, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

html {
    scroll-behavior: smooth;
}

#root {
    width: 100%;
    height: 100%;
}

body {
    background-color: #000;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}